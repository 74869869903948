@import url("https://fonts.googleapis.com/css?family=Hind&display=swap");

.preview {
  @import url("https://fonts.googleapis.com/css?family=Hind&display=swap");

  .text-header {
    font-size: 16px;
    color: #000000;
    font-family: Hind;
  }

  .step-title {
    font-size: 27px;
    color: #000000;
    font-family: Hind;
  }

  .row .choice {
    font-family: Hind;
  }

  .footernote {
    font-size: 14px;
    color: #000000;
    font-family: Hind;
  }
  .step-note {
    font-size: 14px;
    color: #000000;
    font-family: Hind;
  }
  .review {
    font-size: 11px;
    color: #000000;
    font-family: Hind;
  }

  .hs-button.primary {
    font-size: 13px;
    color: #f3f8fa;
    font-family: Hind;
  }

  .errors.active {
    font-size: 19px;
    color: #ff0000;
    font-family: Hind;
  }

  // #mainwrap {
  //   font-size: 24px;
  //   color: #000000;
  //   font-family: Hind;
  // }

  .text-default {
    color: #999fa4;
  }
  .text-accent {
    color: #4ec3e0;
  }
  .cta-primary:hover {
    color: #ffffff;

    background-color: #46b0ca;

    transform: scale(1.05);
  }

  #mainwrap .choicewrap div.choice {
    border: 1px solid #dddddd;
  }

  #mainwrap .choicewrap div.choice.selected,
  #mainwrap div.step.match-step .choicewrap div.choice.selected {
    background-color: #ffffff;
    border: 1px solid #4ec3e0;
  }

  .match-show-toggler {
    background-color: #ffffff;
    border: 1px dotted #4ec3e0;
  }

  #mainwrap .white-box {
    border: 1px solid #dddddd;
    padding: 1em;
    background-color: #ffffff;
  }

  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  input[type="file"],
  textarea,
  select {
    font-size: 18px;
    font-weight: 400;
    color: #5c656d;
    letter-spacing: 0px;
    margin-bottom: 10px;
    line-height: 1.444em;
    border: 1px solid #dddddd;

    background-color: #ffffff;
  }

  #innerwrap {
    white-space: normal;
    background-color: #f3f8fa;
    order: 4;
  }

  #formIdHandlebar {
    background-color: #f3f8fa;
  }

  .poweredby {
    justify-content: left;
    order: 5;
    background-color: #f3f8fa;
  }

  .poweredby:after {
    background: url("../assets/dwellful_black.svg") no-repeat;
    background-size: contain;
  }

  .step-note .highlight {
    font-size: 1.1em;
    color: #4ec3e0;
  }

  .hs-button {
    border-radius: 0px;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 25%);
  }

  .hs-button.primary {
    transition: all 0.4s ease-in-out;
    background-color: #4ec3e0;
  }

  .hs-button.primary:hover {
    color: #ffffff;

    background-color: #4ec3e0;

    transform: scale(1.05);
  }

  #mainwrap label.std {
    background-color: #f6f7f7;
    border: 1px solid #dddddd;
    border-bottom-width: 0;
    padding: 6px;
    font-size: 13px;
    margin-bottom: 0;
    display: block;
    color: #333f48;
    font-family: Hind;
  }

  div.step.match-step .choicewrap div.choice.selected {
    background-color: white;
    border-color: #ffffff;
  }

  .loader {
    color: #4ec3e0;
  }

  .loader:before {
    background: #f3f8fa;
  }
  .loader:after {
    background: #f3f8fa;
  }

  .relogowrap {
    display: block;
  }
  .relogo {
    filter: brightness(0) invert(21%) sepia(17%) saturate(598%) hue-rotate(167deg) brightness(96%) contrast(90%);
  }

  .loader-wrapper {
    background: white;
  }

  .hhs-divider {
    border-bottom: 2px solid #4ec3e0;
    width: 50px;
    margin: 0 0 50px 0;
  }
  .fa,
  .fal,
  .fab,
  .fas,
  .far {
    color: #4ec3e0;
  }
  .hhs-icon-con svg {
    fill: #4ec3e0;
  }

  .hs-search-highlight,
  .blog-listing-wrapper .post-listing .post-item .post-body a,
  .blog-post-wrapper .post-body a,
  #mainwrap a {
    color: #4ec3e0;
  }
  .hs-search-results__title:hover,
  .blog-listing-wrapper .post-listing .post-item .post-body a:hover,
  .blog-post-wrapper .post-body a:hover,
  #mainwrap a:hover,
  #mainwrap a:focus {
    color: #276270;
  }

  .hhs-search-modal button {
    background-color: #4ec3e0;
  }
  #mainwrap [type="radio"]:checked + label:after,
  #mainwrap [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 15px;
    height: 15px;
    background: #4ec3e0;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  #mainwrap [type="radio"]:checked + label {
    font-size: 18px;
    color: #333f48;
    font-family: Hind;
  }

  #mainwrap [type="radio"]:unchecked + label {
    font-size: 18px;
    color: #999fa4;
    font-family: Hind;
  }

  .progressbar {
    width: 80%;
    border-radius: 10px;
    background-color: #eceeef;
    order: 1;
    margin: 0px;
  }

  .progressbar-inner {
    border-radius: 10px;
    background-color: #4ec3e0;
  }

  .splash-bkg {
    background-image: url(https://www.dwellful.com/hubfs/tools/fpbkg_10.jpg);
    opacity: 0.25;
  }
}
